import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import {
  alpha,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'

import { useSubscription } from '@core/api'
import FTGTooltip from '@core/components/FTGTooltip'
import { getContentTypeLabel } from '@core/constants/content-type'

import { AiModel } from '../main-types'
import * as queries from './admin-queries'
import { AdminSettings } from './admin-settings-state'

export type OnSelect = (model: AiModel) => Promise<void>

type ModelRowProps = {
  model: AiModel
  onSelect: OnSelect
}

const ModelRow = (props: ModelRowProps) => {
  const { model } = props
  const [loading, setLoading] = useState(false)

  const internalValue = !model.isInternal ? 'empty' : 'checked'
  const internalTooltip =
    internalValue === 'empty' ? (
      <Box maxWidth={120}>Make model available only to internal users</Box>
    ) : (
      <Box maxWidth={120}>Make model available to all users</Box>
    )

  const handleChange = async () => {
    setLoading(true)
    await props.onSelect(model)
    setLoading(false)
  }

  return (
    <ListItem
      data-testid={`permission:${model.name}`}
      sx={{
        maxWidth: '100%',
        '& .MuiFormControlLabel-label': {
          fontWeight: 'normal',
          textTransform: 'capitalize',
        },
        '&: nth-of-type(odd)': {
          background: (theme) => alpha(theme.palette.other.downriver, 0.03),
        },
      }}
    >
      <ListItemText
        sx={{ px: 1, flexShrink: 0, width: `calc(100% - 80px)` }}
        primary={model.name}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true, component: 'div' }}
        secondary={
          <>
            <Typography variant="body2" color="primary.light" sx={{ mt: 1 }}>
              <b>Type: </b> {getContentTypeLabel(model.type)}
            </Typography>
            <Typography variant="body2" noWrap color="primary.light">
              <b>Internal Name: </b>
              {model.internalName}
            </Typography>
            <Typography variant="body2" noWrap color="primary.light">
              <b>Created at: </b>
              {new Date(model.createdAt).toLocaleString()}
            </Typography>
          </>
        }
      />

      <FTGTooltip title={internalTooltip}>
        <Box width={80} textAlign="center">
          <Checkbox
            onChange={handleChange}
            checked={internalValue === 'checked'}
            disabled={loading}
            color="secondary"
            aria-label={`model-${model.name}-${internalValue}`}
          />
        </Box>
      </FTGTooltip>
    </ListItem>
  )
}

type SearchBarProps = {
  handleChange: (value: string) => void
}

const SearchBar = ({ handleChange }: SearchBarProps) => {
  return (
    <Box component="form" noValidate autoComplete="off" aria-label="Search Users">
      <Input
        id="search-users"
        size="medium"
        sx={{
          px: 1,
          bgcolor: '#ebecf0',
          borderRadius: 0.75,
          '& fieldset': { border: 'none' },
        }}
        inputProps={{
          'aria-label': 'Search Users',
        }}
        placeholder="Search Models"
        endAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        onChange={(e) => handleChange(e.target.value)}
      />
    </Box>
  )
}
type AdminManageModelsProps = {
  adminSettings: AdminSettings
}

export const AdminManageModels = ({ adminSettings }: AdminManageModelsProps) => {
  const { handleUpdateModel, handleClose } = adminSettings
  const [searchModels, setSearchModels] = useState('')

  const { data } = useSubscription(queries.GET_MODELS, {
    context: { role: 'internal_admin' },
  })

  const models = React.useMemo(() => {
    return data?.models.filter((model) => {
      const search = searchModels.toLowerCase().trim()
      return (
        model.name.toLowerCase().includes(search) ||
        getContentTypeLabel(model.type).toLowerCase().includes(search) ||
        model.internalName.toLowerCase().includes(search)
      )
    })
  }, [data, searchModels])

  return (
    <Box>
      <DialogContent sx={{ overflowY: 'auto' }}>
        <Box>
          <SearchBar handleChange={setSearchModels} />
          <Box mt={5} display="flex" justifyContent="space-between">
            <Box typography="h5" flexGrow={1}>
              Model
            </Box>
            <Box typography="h5" width={80} textAlign="center">
              Internal
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <List sx={{ overflowY: 'auto', mb: 3, height: '50vh' }}>
        {!models && (
          <Box display="flex" justifyContent="center" alignItems="center" height={200} width="100%">
            <CircularProgress />
          </Box>
        )}
        {models?.map((model) => (
          <ModelRow key={model.id} model={model} onSelect={handleUpdateModel} />
        ))}
      </List>

      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Done
        </Button>
      </DialogActions>
    </Box>
  )
}
