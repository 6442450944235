import { Route } from '@core/constants/routes'
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Customer, FormValues } from './main-types'

export interface RouteMatch {
  path: Route
  url: string
  params: Record<string, any>
}

export type Role = 'User' | 'Admin'

export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  name: string
  role: Role
  canResetCustomerData: boolean
  canDeleteProjects: boolean
  picture: string
  internalAdmin: boolean
  isInternal: boolean
}

export interface State {
  customer: Customer | null
  user: User | null
  routeMatch: RouteMatch | null
  downloadNotification: boolean
}

export function getInitialState(): State {
  return {
    customer: null,
    user: null,
    routeMatch: null,
    downloadNotification: false,
  }
}

export const routeEnter = createAction<RouteMatch>('main/routeEnter')
export const routeLeave = createAction<RouteMatch>('main/routeLeave')

export const slice = createSlice({
  name: 'main',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },

    setCustomer(state: State, action: PayloadAction<FormValues>) {
      state.customer = { ...state.customer!, ...action.payload }
    },
    reset(state: State) {
      Object.assign(state, getInitialState())
    },
  },
  extraReducers: (builder) => {
    builder.addCase(routeEnter, (state, action) => {
      state.routeMatch = action.payload
    })
  },
})

export default slice

export const actions = {
  ...slice.actions,
  routeEnter,
  routeLeave,
}
