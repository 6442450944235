import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material'
import _ from 'lodash'

import { exportOptions, FormValues, pretestOptions } from '../main-types'
import { AdminSettings } from './admin-settings-state'

const defaultValues: FormValues = {
  hasOptionRationale: false,
  features: {
    export_file: [],
    export_math: '',
    export_math_delimiter: '',
    learn_v2: false,
    possible_keys: false,
    rationales: false,
  },
}

export const AdminManageCustomer = ({ adminSettings }: { adminSettings: AdminSettings }) => {
  const { state, handleClose, handleUpdateCustomer } = adminSettings

  const customer = adminSettings.customer!

  const values = React.useMemo<FormValues>(() => {
    const result = _.pick(_.merge({}, defaultValues, _.cloneDeep(customer!)), [
      'features',
      'hasOptionRationale',
    ])

    result.features.export_file = _.uniq(result.features.export_file)

    return result
  }, [customer])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result: FormValues = _.cloneDeep(values)

    // General handling of multi-field features, for now all export_ features
    if (e.target.name.startsWith('features.export_')) {
      const feat = e.target.name.split('.')[1]
      const key = e.target.name.split('.')[2]

      if (e.target.checked) {
        result.features[feat].push(key)
      } else {
        _.remove(result.features[feat], (item) => item === key)
      }
    } else {
      // set it to whatever checked was actual
      _.set(result, e.target.name, e.target.checked)
    }

    handleUpdateCustomer(result)
  }

  const getProps: (label: string, field: string) => FormControlLabelProps = (label, field) => {
    let checked = _.get(values, field, false)

    if (field.includes('export_')) {
      const feat = field.split('.')[1]
      checked = values.features[feat]?.includes(_.last(field.split('.'))!)
    }

    return {
      disabled: state.loading,
      onChange: handleChange,
      slotProps: { typography: { fontSize: 14 } },
      sx: { display: 'flex' },
      control: <Checkbox size="small" color="secondary" />,
      name: field,
      checked,
      label,
    }
  }

  return (
    <Box>
      <DialogContent sx={{ pt: '0px !important' }}>
        <Box typography="caption" fontWeight="bold">
          ID
        </Box>
        <Box>{customer.id}</Box>

        <Box typography="caption" fontWeight="bold" mt={1}>
          Customer Name
        </Box>
        <Box>{customer.name}</Box>

        <Box display="flex" justifyContent="space-between">
          <Box>
            <Box typography="caption" fontWeight="bold" mt={1}>
              Created At
            </Box>
            <Box>{new Date(customer.createdAt).toLocaleString()}</Box>
          </Box>

          <Box>
            <Box typography="caption" fontWeight="bold" mt={1}>
              Updated At
            </Box>
            <Box>{new Date(customer.updatedAt).toLocaleString()}</Box>
          </Box>
        </Box>
        <Box typography="caption" fontWeight="bold" mt={1}>
          SSO Connection Name
        </Box>
        <Box>{customer.ssoConnectionName || 'None'}</Box>

        <Divider sx={{ mt: 2 }}>Flags</Divider>
        <FormControlLabel {...getProps('Learn by customer', 'features.learn_v2')} />
        <FormControlLabel {...getProps('Option rationales', 'hasOptionRationale')} />
        <FormControlLabel {...getProps('Possible Keys', 'features.possible_keys')} />
        <FormControlLabel {...getProps('Item Rationales', 'features.rationales')} />
        <FormControlLabel
          {...getProps('ProBuilder Integration', 'features.probuilder_integration')}
        />
        <Divider sx={{ mt: 2 }}>Pre-test Flags</Divider>
        <Box display="flex" justifyContent="space-between" my={1}>
          {pretestOptions.map((option) => (
            <FormControlLabel
              {...getProps(option.label, `features.${option.value}`)}
              key={option.label}
            />
          ))}
        </Box>
        <Divider sx={{ mt: 2 }}>Export Options</Divider>
        <Box display="flex" justifyContent="space-between" my={1}>
          {exportOptions.map((option) => (
            <FormControlLabel
              {...getProps(option, `features.export_file.${option}`)}
              key={option}
            />
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={state.loading} variant="contained">
          Done
        </Button>
      </DialogActions>
    </Box>
  )
}
