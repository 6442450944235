export default {
  MODE: import.meta.env.MODE,
  SENTRY_DSN: import.meta.env.SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE: import.meta.env.SENTRY_TRACES_SAMPLE_RATE,
  AUTH0_DOMAIN: import.meta.env.AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: import.meta.env.AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: import.meta.env.AUTH0_AUDIENCE,
  FTG_API_URL: import.meta.env.FTG_API_URL,
  FTG_WS_URL: import.meta.env.FTG_WS_URL,
  PENDO_API_KEY: import.meta.env.PENDO_API_KEY,
  PENDO_ENABLED: import.meta.env.PENDO_ENABLED,
  SENTRY_RELEASE: import.meta.env.SENTRY_RELEASE,
  ENV: import.meta.env.ENV,
}
