import _ from 'lodash'

import { CLOZE, ITEM_SET, MCQ } from '@core/constants/content-type'

import {
  Answer,
  AnswerStatus,
  Comment,
  ContentCloze,
  ContentMCQ,
  Item,
  ItemMap,
  ItemRoot,
  RawChildContentVersion,
  RawComment,
  RawItem,
  RawItemSet,
} from './author-cloze-types'

// store in memory to avoid collisions
let commentId = 0

export function getUniqueCommentId() {
  if (!commentId) {
    commentId = Date.now()
  }
  commentId += 1

  return `comment-${commentId}`
}
export const watchers = {
  [ITEM_SET]: ['stimulus', 'comments'],
  [CLOZE]: ['options'],
  [MCQ]: ['question', 'answers'],
}

export function checkJSON(prev: any, curr: any) {
  return JSON.stringify(prev) !== JSON.stringify(curr)
}

export function filterExcluded(answers: Answer[]) {
  return answers.filter((answer) => ![AnswerStatus.excluded, null].includes(answer.status))
}

// this is for the final content and the last content
export function checkUnsavedChanges(item: Item | ItemRoot): string | false {
  const lastContent = item.currentContent?.content
  const finalContent = item.savedContent?.content

  if (!lastContent || !finalContent) return false

  const checkAnswers = (name: 'answers' | 'options') => {
    if (
      checkJSON(filterExcluded(lastContent?.[name] || []), filterExcluded(finalContent[name] || []))
    ) {
      return true
    }
  }

  if ('answers' in lastContent! && 'answers' in finalContent) {
    if (checkAnswers('answers')) {
      return 'answers'
    }
  }

  if ('options' in lastContent! && 'options' in finalContent) {
    if (checkAnswers('options')) {
      return 'options'
    }
  }

  for (const field of ['question', 'cloze_ids', 'mcq_ids', 'stem', 'stimulus']) {
    if (field in lastContent! && field in finalContent) {
      if (checkJSON(lastContent?.[field], finalContent[field])) {
        return field
      }
    }
  }

  return false
}

// this is only for the last content, but comparing with the previous last content
export function checkContentChanged(prev: Item | ItemRoot, curr: Item | ItemRoot): boolean {
  return !_.isEqual(prev.currentContent, curr.currentContent)
}

export function checkLoading(item?: Item | ItemRoot): boolean {
  if (!item) return true
  return item.status === 'NOT_STARTED' || item.status === 'IN_PROGRESS'
}

export function getOptions(item: Item | ItemRoot): Answer[] {
  const field = item.type === CLOZE ? 'options' : item.type === MCQ ? 'answers' : null

  if (!field || !item.currentContent.content || !(field in item.currentContent.content)) return []

  const items: Answer[] = item.currentContent.content[field]

  return items
}

export const common = {
  comments: {
    from: (comments: RawComment[]): Comment[] => {
      const outCommetns: Comment[] = comments.map((comment) => {
        return { ...comment, id: comment.id || getUniqueCommentId() }
      })
      return outCommetns
    },
  },
}

const transformChild = {
  from: (item: RawChildContentVersion, type: string): ContentMCQ | ContentCloze => {
    if (!item?.content) return {}

    const { content } = item

    if (type === MCQ) {
      return {
        ...content,
        question: content?.question,
        answers: content?.answers,
      }
    }

    return {
      ...content,
      question: content?.question,
      options: content?.options,
      stimulus: content?.stem,
    }
  },
}

export function normalizeChildren(children: RawItem[]): Record<string, Item> {
  const items: Record<string, Item> = {}

  for (const child of children) {
    const { currentContent, savedContent, originalContent, ...rest } = child

    if (child.type === MCQ) {
      items[child.id] = {
        ...rest,
        currentContent: {
          content: transformChild.from(currentContent?.[0], MCQ),
          submodels: currentContent?.[0]?.submodels,
        },
        savedContent: {
          content: savedContent?.length ? transformChild.from(savedContent?.[0], MCQ) : null,
          submodels: savedContent?.[0]?.submodels,
        },
        originalContent: {
          submodels: originalContent?.[0]?.submodels || [],
        },
        type: MCQ,
      }
    } else {
      items[child.id] = {
        ...rest,
        currentContent: {
          content: transformChild.from(currentContent?.[0], CLOZE),
          submodels: currentContent?.[0]?.submodels,
        },
        savedContent: {
          content: savedContent?.length ? transformChild.from(savedContent?.[0], CLOZE) : null,
          submodels: savedContent?.[0]?.submodels,
        },
        originalContent: {
          submodels: originalContent?.[0]?.submodels || [],
        },
        type: CLOZE,
      }
    }
  }
  return items
}

export function normalizeItemSet(itemSet: RawItemSet): {
  root: ItemMap['root']
  children: Record<string, Item>
} {
  const { children, currentContent, savedContent, originalContent, ...rawRoot } = itemSet

  const items = normalizeChildren(children)
  const root: ItemMap['root'] = {
    ...rawRoot,
    currentContent: {
      content: currentContent[0].content,
      externalMetadata: currentContent?.length ? currentContent[0].external_metadata : null,
      submodels: currentContent?.[0]?.submodels,
      qualityMetrics: currentContent?.[0].qualityMetrics || [],
      comments: common.comments.from(currentContent?.[0].comments || []),
    },
    savedContent: {
      content: savedContent?.length ? savedContent?.[0].content : null,
      externalMetadata: savedContent?.length ? savedContent[0].external_metadata : null,
      submodels: savedContent?.[0]?.submodels,
    },
    originalContent: {
      submodels: originalContent?.[0]?.submodels || [],
    },
  }

  return { root, children: items }
}
