import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'

import { useMutation, useQuery } from '@apollo/client'
import { TextField, TextFieldProps } from '@core/components/textfield/textfield'
import { addSnack } from '@core/snack/snack-state'

import {
  GET_CUSTOMER_INTEGRATIONS,
  TEST_INTEGRATION,
  UPDATE_CUSTOMER_INTEGRATION,
  type Integration,
  type UpdateValues,
} from './admin-queries'

const defaultValues: UpdateValues = {
  access_key: '',
  secret_key: '',
  subject_ref: '',
  centre_ref: '',
  subject_url_id: '',
  target: 'BILPAS',
}

const getDefaultValues = (integration?: Integration) => {
  if (!integration) return defaultValues

  return {
    ...integration.data,
    access_key: integration.access_key,
    secret_key: integration.secret_key,
  }
}

export const AdminManageIntegrations = () => {
  const form = useForm({ defaultValues })
  const { register, formState, handleSubmit } = form
  const defaultTargetValue = React.useRef(form.getValues('target'))

  const [initializedWithPassword, setInitializedWithPassword] = React.useState(false)

  const customerQuery = useQuery(GET_CUSTOMER_INTEGRATIONS, {
    context: { role: 'internal_admin' },
    nextFetchPolicy: 'network-only',
    onCompleted(data) {
      const values = getDefaultValues(data.integration[0])
      form.reset(values)
      defaultTargetValue.current = values.target

      setInitializedWithPassword(Boolean(values.secret_key))
    },
  })

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER_INTEGRATION, {
    context: { role: 'internal_admin' },
  })

  const [testConnection, testConnectionData] = useMutation(TEST_INTEGRATION, {
    context: { role: 'internal_admin' },
  })

  const handleTestConnection = async () => {
    await testConnection({
      variables: {
        integrationType: 'ProBuilder',
      },
      context: { role: 'internal_admin' },
    })
  }

  const getProps = (field: keyof UpdateValues, maxLength = 128): TextFieldProps => {
    const error = formState.errors[field]
    let message = error?.message

    if (error?.type === 'maxLength') {
      message = `Max length of ${maxLength}`
    } else if (error?.type === 'minLength') {
      message = 'Min length of 1'
    }

    const fieldProps = register(field, {
      maxLength,
      minLength: 1,
      required: 'Required',
    })

    return {
      ...fieldProps,
      disabled: formState.isSubmitting || testConnectionData.loading,
      error: Boolean(error),
      helperText: message,
      autoComplete: 'off',
    }
  }

  const onSubmit = async (values: UpdateValues) => {
    try {
      testConnectionData.reset()

      const response = await updateCustomer({
        variables: {
          integrationType: 'ProBuilder',
          data: values,
        },
        context: { role: 'internal_admin' },
      })

      if (response.data?.update_customer_integration?.ok) {
        addSnack({
          message: 'Integration updated successfully',
          severity: 'success',
        })

        const refetchResponse = await customerQuery.refetch()
        form.reset(getDefaultValues(refetchResponse.data.integration[0]))
        setInitializedWithPassword(true)

        return
      }

      console.error(
        response?.data?.update_customer_integration,
        'AdminManageIntegrations.onSubmit response error',
      )

      addSnack({
        message: 'Something went wrong',
        severity: 'warning',
      })
    } catch (error) {
      const firstError = error.graphQLErrors?.[0]?.extensions

      const errorMessage =
        firstError?.code === 'ValidationError'
          ? `${firstError.loc[1]}: ${firstError.message} `
          : 'Something went wrong'

      console.error(error, 'AdminManageIntegrations.onSubmit error', firstError)

      addSnack({
        message: errorMessage,
        severity: 'error',
      })
    }
  }

  if (customerQuery.loading) {
    return <div>loading</div>
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} name="probuilder-integration">
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          ProBuilder Integration
        </Typography>

        <TextField
          required
          {...getProps('target')}
          label="Target"
          aria-label="ProBuilder target"
          fullWidth
          select
          key={defaultTargetValue.current}
          defaultValue={defaultTargetValue.current}
        >
          <MenuItem value="BILPAS">BILPAS</MenuItem>
          <MenuItem value="PAS">PAS</MenuItem>
          <MenuItem value="PROD">PROD</MenuItem>
        </TextField>

        <Box my={2} />

        <TextField
          required
          {...getProps('access_key')}
          label="Username"
          aria-label="ProBuilder username"
          fullWidth
        />

        <Box my={2} />

        <TextField
          required
          {...getProps('secret_key', 1024)}
          label="Password"
          type="password"
          aria-label="ProBuilder password"
          fullWidth
          disabled={initializedWithPassword}
        />

        {initializedWithPassword && (
          <Button
            size="small"
            sx={{ mt: 1 }}
            disabled={testConnectionData.loading}
            onClick={() => {
              setInitializedWithPassword(false)
              form.setValue('secret_key', '')
              testConnectionData.reset()
              setTimeout(() => {
                form.setFocus('secret_key')
              }, 100)
            }}
          >
            Clear Password
          </Button>
        )}

        <Divider sx={{ my: 4 }} />

        <TextField
          required
          {...getProps('centre_ref')}
          label="Centre Reference"
          aria-label="ProBuilder centre_ref"
          fullWidth
        />

        <Box mt={2} />
        <TextField
          required
          {...getProps('subject_ref')}
          label="Subject Reference"
          aria-label="ProBuilder subject_ref"
          fullWidth
        />

        <Box mt={2} />
        <TextField
          required
          {...getProps('subject_url_id')}
          label="Subject URL ID"
          aria-label="ProBuilder subject_url_id"
          helperText="ID used to build the destination URL"
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        {initializedWithPassword && (
          <LoadingButton
            onClick={handleTestConnection}
            variant="contained"
            loading={testConnectionData.loading}
            disabled={formState.isSubmitting || formState.isDirty}
          >
            Test Integration
          </LoadingButton>
        )}

        <LoadingButton
          variant="contained"
          disabled={formState.isSubmitting || !formState.isDirty}
          loading={formState.isSubmitting}
          type="submit"
        >
          Update
        </LoadingButton>
      </DialogActions>

      {testConnectionData.data?.update_customer_integration && (
        <Box my={1} px={4}>
          <Divider sx={{ my: 4 }} />
          <TextField
            label="Connection Result"
            value={JSON.stringify(
              testConnectionData.data.update_customer_integration?.result,
              null,
              2,
            )}
            maxRows={16}
            multiline
            onChange={() => {}}
            fullWidth
            spellCheck={false}
          />
        </Box>
      )}
    </Box>
  )
}
