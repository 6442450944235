export const DEBOUNCE_TIMEOUT = 500
export const COMMENT_SAVE_DEBOUNCE_TIMEOUT = 500
export const STORY_DEBOUNCE_TIMEOUT = 1000
export const AUTO_SAVE_TIMEOUT = 1000
export const SAVE_ITEM_DEBOUNCE_TIMEOUT = 1500

const minute = 60 * 1000
const hour = 60 * minute

export const TIMEOUTS = {
  COMMENT_SAVE_DEBOUNCE_TIMEOUT: 500,
  AUTO_SAVE_TIMEOUT,
  TOKEN_REFRESH: 58 * 1000,
  BECOME_IDLE: 5 * minute,
  MAX_USER_IDLE_TIME: 4 * hour,
  MAX_AUTH_SESSION_LENGTH: 12 * hour,
  UI_AUTH_SESSION_EXPIRATION_WARNING: 15 * minute,
}

export default TIMEOUTS
