import ErrorHandler from '@core/api/ErrorHandler'
import { addSnack } from '@core/snack/snack-state'
import { createAsyncThunk } from '@reduxjs/toolkit'

import * as queries from './users-queries'
import type { RawPermission } from './users-queries'
import type { Permission } from './users-types'

const context = { headers: { 'x-hasura-role': 'customer_admin' } }

export const createUsers = createAsyncThunk<
  | { status: 'error'; message: string; email: string }[]
  | { id: string; email: string; ticket: string; status: 'success' }[],
  { email: string; first_name: string; last_name: string }[]
>('users/createUser', async (users, { extra }) => {
  try {
    const { data } = await extra.client.mutate({
      mutation: queries.CREATE_USERS,
      context,
      variables: { users },
    })

    return data.create_users?.responses || []
  } catch (error) {
    const { message } = ErrorHandler(error) as any
    addSnack({ severity: 'error', message })
    return { status: 'error', message: error.message }
  }
})

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (variables: queries.UpdateUserInput, { extra }): Promise<queries.UpdateUserOutput> => {
    try {
      const { data } = await extra.client.mutate({
        mutation: queries.UPDATE_USER,
        context,
        variables,
      })

      if (!data?.update_user_info?.response) {
        throw new Error('No response from server')
      }

      return data.update_user_info.response
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      addSnack({ severity: 'error', message })
      return { status: 'error', message: error.message }
    }
  },
)

export const assignRole = createAsyncThunk(
  'users/assignRole',
  async (role: string, { extra, getState }) => {
    try {
      const { users, selected } = getState().users

      let total = 0

      await Promise.all(
        selected.map(async (id) => {
          const user = users?.find((u) => u.id === id)

          if (!user) return

          await extra.client.mutate({
            mutation: queries.UPDATE_USER,
            context,
            variables: {
              user: {
                id,
                first_name: user.firstName,
                last_name: user.lastName,
                role,
              },
            },
          })

          total += 1
        }),
      )

      if (total > 0) {
        addSnack({
          message: `${role} assigned successfully!`,
          severity: 'success',
        })
      }
    } catch (error) {
      ErrorHandler(error) as any
      addSnack({
        message: `Error when trying to change user role permissions. ${error.message}`,
        severity: 'error',
      })
      throw error
    }
  },
)

export const deleteUser = createAsyncThunk('users/deleteUser', async (_, { extra, getState }) => {
  try {
    const usersSelected = getState().users.selected

    const { data } = await extra.client.mutate({
      mutation: queries.DELETE_USER,
      context,
      variables: {
        users: usersSelected,
      },
    })

    if (data.update_user?.affected_rows > 0) {
      addSnack({
        message: `User${usersSelected.length > 1 ? 's' : ''} deleted successfully!`,
        severity: 'success',
      })
      return usersSelected
    }
  } catch (error) {
    ErrorHandler(error) as any
    addSnack({
      message: `Error when trying to delete users. ${error.message}`,
      severity: 'error',
    })
    throw error
  }
})

export const deleteUsers = createAsyncThunk<void, string[]>(
  'users/deleteUsers',
  async (ids, { extra }) => {
    try {
      await extra.client.mutate({
        mutation: queries.DELETE_USER,
        context,
        variables: { users: ids },
      })
    } catch (error) {
      ErrorHandler(error) as any
      addSnack({
        message: `Error when trying to delete users. ${error.message}`,
        severity: 'error',
      })
      throw error
    }
  },
)

interface InsertPermissionParams {
  items: RawPermission[]
}

export const setModelPermission = createAsyncThunk<Permission[], InsertPermissionParams>(
  'admin/setModelPermissions',
  async (variables, { extra }) => {
    try {
      const { data } = await extra.client.mutate({
        fetchPolicy: 'network-only',
        mutation: queries.UPSERT_AI_MODEL_PERMISSIONS,
        context,
        variables,
      })

      return data!.result.returning
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      addSnack({ severity: 'error', message })
      throw error
    }
  },
)
