import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

import { useQuery } from '@apollo/client'
import FTGMultiSelect, { Option } from '@core/components/FTGMultiSelect'

import type { FormValues, MathKeyboards } from '../main-types'
import { exportMathDelimiterOptions, exportMathOptions, mathKeyboards } from '../main-types'
import * as queries from './admin-queries'
import { AdminSettings } from './admin-settings-state'

const defaultValues: FormValues = {
  hasOptionRationale: false,
  features: {
    export_file: [],
    export_math: '',
    export_math_delimiter: '',
    math_keyboards: {},
    learn_v2: false,
    possible_keys: false,
    rationales: false,
  },
}

export const AdminManageMath = ({ adminSettings }: { adminSettings: AdminSettings }) => {
  const [btnDisabled, setBtnDisabled] = React.useState(true)
  const { handleClose, handleUpdateCustomer } = adminSettings
  const customer = adminSettings.customer!

  const { features, hasOptionRationale } = useMemo<FormValues>(() => {
    const result = _.pick(_.merge({}, defaultValues, _.cloneDeep(customer!)), [
      'features',
      'hasOptionRationale',
    ])

    return result
  }, [customer])

  const [models, setModels] = useState<Option[]>([])
  const [selectedKbs, setSelected] = useState<MathKeyboards>({})

  useQuery(queries.LIST_MODELS, {
    fetchPolicy: 'network-only',
    context: { role: 'internal_admin' },
    onCompleted(data) {
      const modelOpts = data?.models?.map((m) => ({
        label: m.name,
        value: m.internalName,
      }))
      setModels(modelOpts)
    },
    onError(err) {
      console.error(err)
    },
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const result: FormValues['features'] = _.cloneDeep(features)

    const feat = /\./.test(e.target.name) ? e.target.name.split('.')[0] : e.target.name
    const val = /\./.test(e.target.name) ? e.target.name.split('.')[1] : e.target.checked

    result[feat] = val

    handleUpdateCustomer({ features: result, hasOptionRationale })
    setBtnDisabled(false)
  }

  const handleSelectChange = (selected: string[], keyboard: MathKeyboards | string): void => {
    const result = _.cloneDeep(features)

    selected.forEach((uuid) => {
      result.math_keyboards = result.math_keyboards || {}
      result.math_keyboards[uuid] = result.math_keyboards[uuid] || []

      if (!result.math_keyboards[uuid].includes(keyboard)) {
        result.math_keyboards[uuid].push(keyboard)
      }
    })

    for (const uuid in result.math_keyboards) {
      if (!selected.includes(uuid) && result.math_keyboards[uuid].includes(keyboard)) {
        result.math_keyboards[uuid] = _.filter(result.math_keyboards[uuid], (kb) => kb !== keyboard)
      }
    }

    handleUpdateCustomer({ features: result, hasOptionRationale })
    setBtnDisabled(false)
  }

  const getProps: (label: string, field: string) => FormControlLabelProps = (label, field) => {
    const feat = field.split('.')[0]
    const key = field.split('.')[1]

    const checked = key ? _.get(features, feat, '') === key : _.get(features, feat, false)

    return {
      disabled: false,
      onChange: handleChange,
      slotProps: { typography: { fontSize: 14 } },
      sx: { display: 'flex' },
      control: <Checkbox size="small" color="secondary" />,
      name: field,
      checked,
      label,
    }
  }

  useEffect(() => {
    if (models.length > 0) {
      const modelsWith = _.reduce(
        features.math_keyboards,
        (next, curr, key) => {
          if (Array.isArray(curr) && curr.length > 0) {
            curr.forEach((kb) => {
              // eslint-disable-next-line no-param-reassign
              next[kb] = next[kb] || []
              next[kb].push(key)
            })
          }

          return next
        },
        {},
      )

      setSelected(modelsWith)
    }
  }, [features, models])

  return (
    <Box display="flex-col" gap={4}>
      <DialogContent sx={{ overflowY: 'auto' }}>
        <Box display="flex" gap={4} alignItems="center">
          <Box display="flex-col" justifyContent="" my={1}>
            <Typography variant="body2" gutterBottom className="">
              Export Format
            </Typography>
            <Box display="flex">
              {exportMathOptions.map((option) => (
                <FormControlLabel {...getProps(option, `export_math.${option}`)} key={option} />
              ))}
            </Box>
          </Box>

          {features.export_math === exportMathOptions[0] && (
            <Box display="flex-col" justifyContent="" my={1}>
              <Typography variant="body2" gutterBottom className="">
                LaTeX Delimiter characters
              </Typography>
              <Box display="flex">
                {exportMathDelimiterOptions.map((option) => (
                  <FormControlLabel
                    {...getProps(option, `export_math_delimiter.${option}`)}
                    key={option}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>

        {models.length > 0 && (
          <>
            <Divider sx={{ my: 4 }}>Keyboard / Model Options</Divider>
            <Box display="flex" flexWrap="wrap" maxWidth="100%" justifyContent="" gap={2} my={1}>
              {models.length > 0 &&
                mathKeyboards.map((kb) => (
                  <Box display="flex" sx={{ flexDirection: 'column', maxWidth: '45%' }}>
                    <Typography variant="body2" className="">
                      {_.startCase(kb)}:
                    </Typography>
                    <FTGMultiSelect
                      name={`math_keyboards_${kb}`}
                      disabled={false}
                      options={models}
                      selected={selectedKbs[kb]}
                      placeholder="Models"
                      onChange={(selected) => handleSelectChange(selected, kb)}
                    />
                  </Box>
                ))}
            </Box>
          </>
        )}
      </DialogContent>
      <Divider sx={{ my: 2 }} />

      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose} disabled={btnDisabled}>
          Done
        </Button>
      </DialogActions>
    </Box>
  )
}
