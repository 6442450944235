import type { State } from '@containers/comments/comments-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export function getInitialState(): State {
  return {
    itemId: null,
    comments: [],
  }
}

const JOB_FEEDBACK_STEP_KEY = 'cf-'

export const slice = createSlice({
  name: 'comments',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    setComments(state: State, action: PayloadAction<any>) {
      if (action.payload.comments) {
        const comments = action.payload.comments.map((comment, i) => {
          return {
            id: `${JOB_FEEDBACK_STEP_KEY}${i}`,
            ...comment,
          }
        })
        state.comments = comments
      }
    },
    createComment(state: State) {
      const lastElement = state.comments?.length ? state.comments[state.comments.length - 1] : null
      const plusKeyIndex = (i) => parseInt(i, 10) + 1
      const newKey = lastElement
        ? // @ts-ignore
          `${JOB_FEEDBACK_STEP_KEY}${plusKeyIndex(lastElement.id.split('-')[1])}`
        : `${JOB_FEEDBACK_STEP_KEY}0`

      state.comments = [
        ...state.comments,
        {
          id: newKey,
          name: '',
          value: '',
        },
      ]
    },
    updateComment(
      state: State,
      action: PayloadAction<{ id: string; field: string; value: string }>,
    ) {
      const index = state.comments?.findIndex((x) => x?.id === action.payload.id)
      if (index === -1) return
      state.comments[index][action.payload.field] = action.payload.value
    },
    deleteComment(state: State, action: PayloadAction<string>) {
      const index = state.comments.findIndex((x: any) => x?.id === action.payload)
      if (index === -1) return
      state.comments.splice(index, 1)
    },
    resetState(state: State) {
      Object.assign(state, getInitialState())
    },
  },
})

export const { actions } = slice
