import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { useSubscription as useApolloSubscription } from '@apollo/client'
import { useMainState } from '@core/main-state'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

type UseSubscription = typeof useApolloSubscription

export const useSubscription: UseSubscription = (query, options) => {
  const isIdle = useMainState((state) => state.isIdle)

  return useApolloSubscription(query, {
    ...options,
    skip: isIdle || options?.skip,
  })
}

export const queryClient = new QueryClient()

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
