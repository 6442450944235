import React from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  LinearProgress,
  Link,
  type StyleProps,
} from '@mui/material'

import FTGTooltip from '@core/components/FTGTooltip'
import { addSnack } from '@core/snack/snack-state'
import { useMutation, useMutationState, useQuery, useQueryClient } from '@tanstack/react-query'

import * as exportAPI from './export-api'
import { createPlaceholderJob, useExportJobs } from './export-items-state'
import * as queries from './export-queries'
import { ExportHeader } from './export-result-common'
import { getErrorMessage } from './export-utils'

const styles = {
  tabTitle: {
    typography: 'h5',
    mt: 2,
    mb: 4,
    px: 3,
  },
  exportJobHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  },
} satisfies StyleProps

const getStatusLabel = (status: string) => {
  const labelMap = {
    completed: 'Completed',
    failed: 'Failed',
    completed_with_issues: 'Completed with issues',
    waiting_user_input: 'Waiting for user input',
  }

  return labelMap[status] || 'Exporting...'
}

type ExportJobRowProps = {
  jobBasic: queries.ExportJobBasic
  isExpanded: boolean
}

const getProgress = (jobDetails?: queries.ExportJobDetails) => {
  if (!jobDetails) return 0

  return ((jobDetails.totalCompleted + jobDetails.totalFailed) / jobDetails.totalItems) * 100 || 0
}

type JobErrorProps = {
  error?: string
  jobDetails?: queries.ExportJobDetails
}

const JobError = ({ jobDetails, error }: JobErrorProps) => {
  const { title, message } = getErrorMessage(jobDetails?.errorDetails)
  let errorMessage = message

  if (jobDetails && jobDetails.totalFailed > 0) {
    errorMessage = 'Check the issues tab for more details'
  }

  if (error) {
    errorMessage = 'Failed to load export details'
  }

  return (
    <Alert severity="error">
      <AlertTitle>{title}</AlertTitle>
      {errorMessage}
    </Alert>
  )
}

const ExportJobRow = ({ jobBasic, isExpanded }: ExportJobRowProps) => {
  const queryClient = useQueryClient()
  const [updateDialog, addExportJob, removeExportJob, isChecked] = useExportJobs((state) => [
    state.updateDialog,
    state.add,
    state.remove,
    state.exportDialog.selectedJobIds.has(jobBasic.id),
  ])

  const exportDetails = useQuery({
    queryKey: exportAPI.exportItemsKeys.details(jobBasic.id),
    queryFn: () => exportAPI.getExportDetails(jobBasic.id),
    enabled: isExpanded,
  })

  const onContinue = useMutation({
    mutationFn: exportAPI.continueExport,
    mutationKey: exportAPI.exportItemsKeys.continueExport(jobBasic.id),
    onError(error, variables) {
      console.error(error, variables)
      addSnack({
        message: 'Error while continuing export job',
        severity: 'error',
      })
    },
    async onSuccess(data, variables) {
      await queryClient.refetchQueries({
        queryKey: exportAPI.exportItemsKeys.list,
      })

      // remove the export job from the status list
      removeExportJob(variables.exportJobId)

      if (variables.mode === 'cancel') {
        // do not select the next job
        updateDialog({ selectedJobId: '' })

        // small message just to be clear that something happened
        addSnack({
          message: 'Export job cancelled',
          severity: 'info',
        })
      } else {
        updateDialog({ selectedJobId: data.continue_export_job.job_id })
        addExportJob(createPlaceholderJob(data.continue_export_job.job_id, jobBasic.exportType))
      }
    },
  })

  const archiveExportJobs = useMutationState({
    filters: {
      status: 'pending',
      mutationKey: exportAPI.exportItemsKeys.archiveExportJobs,
    },
  })

  const isArchiving = archiveExportJobs.length > 0

  const exportJob = exportDetails.data?.exportJob
  const issuesCount = exportJob?.failedItems.length || 0

  const status = exportJob?.status || jobBasic.status

  const progress = getProgress(exportJob)
  const destination = exportJob?.data?.destination_url || ''
  const isFailed = status === 'failed'
  const hasIssues = status === 'waiting_user_input'

  const showContent = Boolean(
    !isFailed && isExpanded && exportDetails.data && !onContinue.isPending,
  )
  const showError = Boolean(
    isExpanded && (isFailed || exportDetails.error) && !exportDetails.isLoading,
  )

  const handleCheck = () => {
    if (isChecked) {
      updateDialog((state) => {
        state.selectedJobIds.delete(jobBasic.id)
      })
    } else {
      updateDialog((state) => {
        state.selectedJobIds.add(jobBasic.id)
      })
    }
  }

  return (
    <Box my={2} bgcolor="background.grey" p={2} mx={3} borderRadius={2}>
      <Box aria-label="export-job-header" sx={styles.exportJobHeader}>
        <Checkbox
          onChange={handleCheck}
          checked={isChecked}
          color="secondary"
          size="small"
          disabled={isArchiving}
        />
        <ExportHeader
          job={jobBasic}
          showBadge={isFailed || hasIssues}
          badgeColor={isFailed ? 'error' : 'warning'}
        />
        <IconButton
          disabled={exportDetails.isLoading}
          onClick={() =>
            updateDialog({
              // set it as empty string to not trigger the useEffect that auto selects the first job
              selectedJobId: isExpanded ? '' : jobBasic.id,
            })
          }
        >
          {isExpanded ? (
            exportDetails.isLoading || onContinue.isPending ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              <ExpandLess />
            )
          ) : (
            <ExpandMore />
          )}
        </IconButton>
      </Box>

      <Collapse in={showError} timeout="auto" unmountOnExit>
        <JobError jobDetails={exportJob} error={exportDetails.error?.message} />
      </Collapse>

      <Collapse in={showContent} timeout="auto" unmountOnExit>
        <Box mt={2}>
          {!['waiting_user_input', 'failed'].includes(jobBasic.status) && (
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                typography="h5"
                sx={{ mb: 1 }}
              >
                <span>{getStatusLabel(status)}</span>
                <span>{progress.toFixed(1)}%</span>
              </Box>
              <LinearProgress
                value={progress}
                variant={exportJob?.totalItems ? 'determinate' : 'indeterminate'}
                color="secondary"
              />

              <br />
              <br />
              <Box component="b" mr={2} sx={{ userSelect: 'none' }}>
                Destination
              </Box>
              {destination ? (
                <Link href={destination} target="_blank" underline="hover" color="secondary">
                  {destination}
                </Link>
              ) : (
                <Box component="span">Creating destination URL...</Box>
              )}

              {!!exportJob?.totalItems && (
                <Box typography="h6" mt={2}>
                  {exportJob.totalItems === 1 ? '1 item' : `${exportJob.totalItems} items`}
                </Box>
              )}
            </Box>
          )}

          {hasIssues && exportJob && (
            <Box sx={{ my: 2 }}>
              <Alert severity="info">
                <Box>
                  {issuesCount === 1 ? '1 issue ' : `${issuesCount} issues `} found in this export.
                  You can:
                  <Box display="flex" flexDirection="column" gap={1} component="ul" mr={0}>
                    <li>Include item with issues in the export and continue</li>
                    <li>
                      Visit 'Issues' tab to see the issues with a direct link to fix the problem and
                      continue
                    </li>
                    <li>Cancel the export</li>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="justify-content"
                    alignItems="center"
                    gap={1}
                    mt={4}
                  >
                    <Box typography="h6" mr="auto">
                      {exportJob.totalItems === 1 ? '1 item' : `${exportJob.totalItems} items`}
                    </Box>
                    <Button
                      size="small"
                      onClick={() =>
                        onContinue.mutate({ exportJobId: jobBasic.id, mode: 'cancel' })
                      }
                    >
                      Cancel
                    </Button>
                    <FTGTooltip
                      title={
                        issuesCount === exportJob.totalItems ? 'All selected items have issues' : ''
                      }
                    >
                      <Box>
                        <Button
                          color="info"
                          variant="contained"
                          size="small"
                          disabled={issuesCount === exportJob.totalItems}
                          onClick={() =>
                            onContinue.mutate({ exportJobId: jobBasic.id, mode: 'skip' })
                          }
                        >
                          Continue (skip)
                        </Button>
                      </Box>
                    </FTGTooltip>
                    <Button
                      color="info"
                      variant="contained"
                      size="small"
                      onClick={() => onContinue.mutate({ exportJobId: jobBasic.id, mode: 'retry' })}
                    >
                      Continue (try again)
                    </Button>
                  </Box>
                </Box>
              </Alert>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  )
}

type ExportJobsHeaderProps = {
  jobs: queries.ExportJobBasic[]
}

const ExportJobsHeader = (props: ExportJobsHeaderProps) => {
  const queryClient = useQueryClient()
  const [selectedJobIds, updateDialog, removeExportJob] = useExportJobs((state) => [
    state.exportDialog.selectedJobIds,
    state.updateDialog,
    state.remove,
  ])

  const archiveExportJobs = useMutation({
    mutationFn: exportAPI.archiveExportJobs,
    mutationKey: exportAPI.exportItemsKeys.archiveExportJobs,
    async onSuccess(data, variables) {
      // remove each export job from the status list (if found)
      variables.forEach((id) => {
        removeExportJob(id)
      })

      // refetch the export jobs
      await queryClient.refetchQueries({
        queryKey: exportAPI.exportItemsKeys.list,
      })

      updateDialog((prev) => {
        // clear the selected items
        prev.selectedJobIds = new Set()

        // if the expanded job is in the list, clear it
        if (prev.selectedJobId && variables.has(prev.selectedJobId)) {
          prev.selectedJobId = ''
        }
      })

      addSnack({
        message: 'Export jobs have been removed',
        severity: 'success',
      })
    },
    onError(error) {
      console.error(error)
      addSnack({
        message: 'Error while removing export jobs',
        severity: 'error',
      })
    },
  })

  const handleCheck = () => {
    updateDialog({
      selectedJobIds:
        selectedJobIds.size === 0 ? new Set(props.jobs.map((job) => job.id)) : new Set(),
    })
  }

  const handleRemove = () => {
    archiveExportJobs.mutate(selectedJobIds)
  }

  const isIndeterminate = selectedJobIds.size > 0 && selectedJobIds.size < props.jobs.length

  return (
    <Box mx={3}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <LoadingButton
          color="tertiary"
          variant="contained"
          disabled={selectedJobIds.size === 0}
          loading={archiveExportJobs.isPending}
          onClick={handleRemove}
        >
          Delete Selected
        </LoadingButton>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Checkbox
          size="small"
          color="secondary"
          aria-label="toggle select all exports"
          onChange={handleCheck}
          checked={selectedJobIds.size > 0}
          indeterminate={isIndeterminate}
          sx={{ ml: 2 }}
        />
        <Box typography="body2" fontWeight={500} color="text.greyBlue">
          {selectedJobIds.size} selected / {props.jobs.length} export
          {props.jobs.length !== 1 && 's'}
        </Box>
      </Box>
    </Box>
  )
}

const StatusTab = () => {
  const [selectedJobId, updateDialog] = useExportJobs((state) => [
    state.exportDialog.selectedJobId,
    state.updateDialog,
  ])

  const latestExports = useQuery({
    queryKey: exportAPI.exportItemsKeys.list,
    queryFn: exportAPI.getLatestExports,
  })

  // auto select the first job, only if there is no selected job
  React.useEffect(() => {
    if (selectedJobId === null && latestExports.data?.exportJobs?.length) {
      const job = latestExports.data.exportJobs[0]
      updateDialog({ selectedJobId: job.id })
    }
  }, [selectedJobId, latestExports.data?.exportJobs])

  const isEmpty = latestExports.data?.exportJobs?.length === 0

  return (
    <>
      <Box sx={styles.tabTitle}>Status</Box>

      {latestExports.error && <Box px={3}>Failed to load exports</Box>}

      {latestExports.isLoading && <Box px={3}>Loading...</Box>}

      {isEmpty && <Box px={3}>No exports found</Box>}

      {Boolean(latestExports.data?.exportJobs?.length) && (
        <>
          <ExportJobsHeader jobs={latestExports.data!.exportJobs} />
          {latestExports.data!.exportJobs.map((job) => (
            <ExportJobRow key={job.id} jobBasic={job} isExpanded={job.id === selectedJobId} />
          ))}
        </>
      )}
    </>
  )
}

export default StatusTab
