import * as React from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import _ from 'lodash'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '12rem',
    },
  },
}

export type Option = {
  label: string
  value: string
}

interface MultiSelectInputs {
  onChange: (opt: any) => void
  options: Option[]
  placeholder?: string
  selected: string[]
  disabled?: boolean
  name: string
}

const MultiSelect = ({
  onChange,
  options,
  placeholder,
  selected = [],
  disabled,
  name,
}: MultiSelectInputs) => {
  const keyMap = _.reduce(
    options,
    (next, curr) => {
      // eslint-disable-next-line no-param-reassign
      next[curr.value] = curr.label
      return next
    },
    {},
  )

  const [selectedOpts, setSelectedOpts] = React.useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof selectedOpts>) => {
    const {
      target: { value },
    } = event

    // On autofill we get a stringified value.
    const newOpts = typeof value === 'string' ? value.split(',') : value

    setSelectedOpts(newOpts)
    onChange(newOpts)
  }

  React.useEffect(() => {
    if (selected && selected.length > 0) {
      setSelectedOpts(selected)
    }
  }, [selected])

  return (
    <Box>
      <FormControl sx={{ my: 2, width: 300 }}>
        <InputLabel id="ftg-multiple-checkbox-label">{placeholder || 'Options'}</InputLabel>
        <Select
          labelId="ftg-multiple-checkbox-label"
          id="ftg-multiple-checkbox"
          multiple
          name={name}
          disabled={disabled}
          value={selectedOpts}
          onChange={handleChange}
          input={<OutlinedInput label={placeholder || 'Options'} />}
          renderValue={(vals) => vals.map((key) => keyMap[key]).join(', ')}
          MenuProps={MenuProps}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              <Checkbox checked={selectedOpts.indexOf(value) > -1} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default MultiSelect
