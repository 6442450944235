import { gql, TypedDocumentNode } from '@apollo/client'

import type { AiModel, Permission } from './users-types'

export const GET_USERS = gql`
  subscription getUsers($userId: String!) {
    users: user(where: { id: { _neq: $userId } }, order_by: { email: asc }) {
      id
      email
      role
      firstName: first_name
      lastName: last_name
      lastAccess: last_access
      createdAt: created_at
      customerId: customer_id
    }
  }
`

export const GET_ALL_PROJECTS = gql`
  subscription getAllProjects {
    project(where: { can_deliver: { _eq: true } }, order_by: { created_at: desc }) {
      id
      name
      owner {
        id
        email
      }
      access {
        read
        user {
          id
          email
        }
      }
      ownerId: owner_id
      items(where: { is_archived: { _neq: true } }) {
        aiModel: ai_model {
          id
          name
        }
      }
    }
  }
`

export const CREATE_USERS = gql`
  mutation createUsers($users: [CreateUserInput!]!) {
    create_users(users: $users) {
      responses
    }
  }
`

export type UpdateUserInput = {
  createPasswordLink?: boolean
  user: {
    id: string
    first_name: string
    last_name: string
    role?: string
  }
}

export type UpdateUserOutput = {
  email?: string
  ticket?: string
  status: 'success' | 'error'
  message?: string
}

export const UPDATE_USER: TypedDocumentNode<
  { update_user_info: { response: UpdateUserOutput } },
  UpdateUserInput
> = gql`
  mutation updateUsers($user: UpdateUserInput!, $createPasswordLink: Boolean) {
    update_user_info(user: $user, create_password_link: $createPasswordLink) {
      response
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUsers($users: [String!]!) {
    update_user(where: { id: { _in: $users } }, _set: { customer_id: null }) {
      affected_rows
    }
  }
`

export const GET_MODELS: TypedDocumentNode<{ models: AiModel[] }> = gql`
  subscription getModels {
    models: ai_model(
      where: { is_archived: { _eq: false }, is_internal: { _eq: false } }
      order_by: { name: asc }
    ) {
      id
      name
      internalName: internal_model_name
      type
      permissions {
        id
        userId: user_id
        read
        trustVote: trust_vote
        possibleKeys: possible_keys
        rationales
        pretest_key
        pretest_content
        pretest_style
        pretest_bias
      }
      isInternal: is_internal
    }
  }
`

export type RawPermission = {
  user_id: string
  ai_model_internal_name: string
  read: boolean
  trust_vote: boolean
  possible_keys: boolean
  rationales: boolean
}

export const UPSERT_AI_MODEL_PERMISSIONS: TypedDocumentNode<
  { result: { returning: Permission[] } },
  { items: RawPermission[] }
> = gql`
  mutation upsert_model_permissions($items: [ai_model_permission_insert_input!]!) {
    result: insert_ai_model_permission(
      objects: $items
      on_conflict: {
        constraint: unique_user_internal_name
        update_columns: [
          read
          trust_vote
          possible_keys
          rationales
          pretest_bias
          pretest_content
          pretest_key
          pretest_style
        ]
      }
    ) {
      affected_rows
      returning {
        id
        userId: user_id
        read
        trustVote: trust_vote
        aiModelInternalName: ai_model_internal_name
        possibleKeys: possible_keys
        rationales
      }
    }
  }
`
