import _ from 'lodash'

import { histogram as histogramColors, tag as tagColors } from '@core/styles/colors'
import type { ContentVersion, Item } from '@pages/project-details/project-details-types'

/**
 * Round number to one decimal place
 * @param {*} num - Number
 */
export const toOneDecimalPlace = (num: number) => parseFloat(num.toFixed(1))

/**
 * Round number to two decimal place
 * @param {*} num - Number
 */
export const toTwoDecimalPlace = (num: number) => parseFloat(num.toFixed(2))

/**
 * Returns an object with domain tags items count
 * @param {*} items - Array
 */
export const getJobDomainTagsCount = (items: Item[]) => {
  return items.reduce(
    (prev, item) => {
      const { domain } = item.aiModel

      if (!domain) return prev

      const prevTags = prev

      if (prevTags[domain]) prevTags[domain] += 1
      else prevTags[domain] = 1

      return prevTags
    },
    {} as Record<string, number>,
  )
}

/**
 * Returns an object with items clone count
 * @param {*} items - Array
 */
export const getItemsCloneCount = (items: Item[]) =>
  items
    .reduce((sum, { clonedFromId, clones }) => {
      if (clonedFromId || clones.aggregate.count) return sum + 1
      return sum
    }, 0)
    .toString()

/**
 * Returns an object with items clone count
 * @param {*} items - Array
 */
export const getItemsInternalCount = (items: Item[]) =>
  items.reduce((sum, { aiModel }) => {
    if (aiModel.isInternal) return sum + 1
    return sum
  }, 0)

/**
 * Returns an object with items topics count
 * @param {*} items - Array
 */
export const getItemsTopicsCount = (items: Item[]): Record<string, number> =>
  items.reduce((prev, item) => {
    const contentVersion = item.savedContent[0] || item.currentContent[0]
    if (!contentVersion) return prev

    const itemTopic = contentVersion?.submodels[0] || contentVersion.content.topic

    const prevTopics = prev

    if (prevTopics[itemTopic]) prevTopics[itemTopic] += 1
    else prevTopics[itemTopic] = 1

    return prevTopics
  }, {})

type QualityMetricsDetails = {
  metricValues: number[]
  totalItemsMetric: number
}

/**
 * Returns the maximum and minimum value of qualityMetrics
 * and total items of the desired qualityMetric from a given array of items
 * @param {*} items - Array
 * @param {*} qualityMetric - String
 */
const getQualityMetricsDetails = (items: Item[], qualityMetric: string): QualityMetricsDetails =>
  items.reduce(
    (acc, item) => {
      const metrics = item.savedContent[0]?.qualityMetrics || item.currentContent[0]?.qualityMetrics
      const currentValue = metrics?.find(({ name }) => name === qualityMetric)?.value

      if (currentValue === undefined) return acc

      const value = typeof currentValue === 'string' ? parseFloat(currentValue) : currentValue

      return {
        metricValues: [Math.min(...acc.metricValues, value), Math.max(...acc.metricValues, value)],
        totalItemsMetric: acc.totalItemsMetric + 1,
      }
    },
    { metricValues: [], totalItemsMetric: 0 },
  )

/**
 * Returns qualityMetrics range of given items array
 * @param {*} items - Array
 * @param {*} qualityMetric - String
 */
export const getItemsQualityMetricsRange = (items: Item[], qualityMetric: string) => {
  const { metricValues, totalItemsMetric } = getQualityMetricsDetails(items, qualityMetric)

  const [min, max] = metricValues || [0, 0]

  if (qualityMetric === 'Type-Token Ratio' || max === 1) {
    return [0, 1]
  }

  const applyBuffer = (value: number) => toOneDecimalPlace(value * 0.1)

  const isUseRange = totalItemsMetric > 1 && min !== max
  const rangeBetween = max - min
  const maxValue = isUseRange ? rangeBetween : max
  const minValue = isUseRange ? rangeBetween : min

  let minRange = toOneDecimalPlace(min - applyBuffer(minValue))

  if (minRange < 0 && min > 0) minRange = 0
  const maxRange = toOneDecimalPlace(max + applyBuffer(maxValue))

  return [Math.floor(minRange), Math.ceil(maxRange)]
}

export type ItemWithMetric = Item & {
  metric: {
    name: string
    value: number
  }
}

export const getMetric = (item: Item, qualityMetric: string) => {
  const metrics = (
    item.savedContent[0]?.qualityMetrics ||
    item.currentContent[0]?.qualityMetrics ||
    []
  ).filter(Boolean)

  const metric = metrics.find(({ name }) => name === qualityMetric)

  if (!metric) return null

  return {
    ...metric,
    value:
      metric?.value &&
      toOneDecimalPlace(typeof metric.value === 'string' ? parseFloat(metric.value) : metric.value),
  }
}

/**
 * Returns formatted quality metric name
 * @param {*} name - string
 */
export const getQualityMetricName = (name?: string) => name?.toLowerCase().split(' ').join('-')

/**
 * Returns mapping of quality metrics to colors
 * @param {*} qualityMetrics - Array
 */
export const getQualityMetricsColors = (qualityMetrics: string[]) => {
  const colors: Record<string, string> = {}

  qualityMetrics.forEach((metricName, index) => {
    const parseQualityMetricName = getQualityMetricName(metricName)

    if (!parseQualityMetricName) return

    const getColorByMetricIndex =
      histogramColors.additionalColors[index % histogramColors.additionalColors.length]
    colors[metricName] = histogramColors.colors[parseQualityMetricName] || getColorByMetricIndex
  })
  return colors
}

/**
 * Returns tag color name by hex color
 * @param {*} color - String
 */
export const findTagColorName = (color: string) => {
  const [name] = Object.entries(tagColors).find(([, { main }]) => main === color) || []

  return name
}

/**
 * Returns true when there are comments
 */
export const hasComments = (comments: ContentVersion['comments']) => {
  return comments.filter((comment) => comment.value).length > 0
}

/**
 * Returns string with [a-zA-Z] letters
 * @param {*} str - String
 */
export const extractAlphabet = (str) => str?.replace(/[^a-zA-Z]/g, '')

/**
 * Returns specific string with [a-zA-Z] letters by position (start, end)
 * @param {*} str - String
 * @param {*} start - Number
 * @param {*} end - Number
 */
export const extractAlphabetByPosition = (str, start, end) => {
  return extractAlphabet(str)?.substring(start, end)
}

/**
 * Sorts array of items by updatedAt and not disabled
 * @param {*} item - Array
 */

type ItemWithRange = Item & { disabled: boolean }

export const sortItems = (itemA: ItemWithRange, itemB: ItemWithRange) => {
  const contentVersionA = itemA.savedContent[0] || itemA.currentContent[0]
  const contentVersionB = itemB.savedContent[0] || itemB.currentContent[0]

  const updatedA = contentVersionA?.updatedAt || itemA.updatedAt || ''
  const updatedB = contentVersionB?.updatedAt || itemB.updatedAt || ''
  // @ts-ignore
  const sort = new Date(updatedB) - new Date(updatedA)

  if (sort !== 0) {
    return sort
  }

  return (itemA.disabled ? 1 : 0) - (itemB.disabled ? 1 : 0)
}

/**
 * Create a date and subtract the number of seconds
 * @param {number} seconds
 * @returns ISO 8601 date string
 */
export const getDateAgo = (seconds = 240 * 60) => {
  const date = new Date()
  date.setSeconds(date.getSeconds() - seconds)

  return date.toISOString()
}

export const getRemoveItemsVars = (selectedItems: Item[]) => {
  const itemIds = selectedItems.map((item) => item.id)
  const jobs = _.groupBy(selectedItems, 'job.id')

  const jobUpdates = Object.entries(jobs).map(([id, items]) => {
    const itemsPassed = items[0].job.itemsPassed - items.length
    const jobStatus = itemsPassed >= items[0].job.itemsRequired ? 'DONE' : 'IN_PROGRESS'

    return {
      where: { id: { _eq: id } },
      _set: { items_passed: itemsPassed, status: jobStatus },
    }
  })

  return {
    ids: itemIds,
    job_updates: jobUpdates,
  }
}
