import { getClient } from '@core/api/api-state'
import { getUserRole } from '@core/main-state'

import * as queries from './export-queries'

export const getLatestExports = async () => {
  const client = getClient()
  const { defaultRole, user } = getUserRole()

  const { data } = await client.query({
    query: queries.GET_LATEST_EXPORT_JOBS,
    context: { role: defaultRole },
    variables: { exportType: 'ProBuilder', userId: user.id },
    fetchPolicy: 'network-only',
  })

  return data
}

export const getExportDetails = async (exportJobId: string) => {
  const client = getClient()
  const { defaultRole } = getUserRole()

  const { data } = await client.query({
    query: queries.EXPORT_RESULT_QUERY,
    context: { role: defaultRole },
    variables: { exportJobId },
    fetchPolicy: 'network-only',
  })

  return data
}

export const continueExport = async (variables: {
  exportJobId: string
  mode: queries.ExportContinueMode
}) => {
  const client = getClient()
  const { defaultRole } = getUserRole()

  const { data } = await client.mutate({
    mutation: queries.CONTINUE_EXPORT,
    context: { role: defaultRole },
    variables,
  })

  return data!
}

export const exportItems = async (variables: {
  exportType: string
  itemIds?: string[]
  projectIds?: string[]
}) => {
  const client = getClient()
  const { defaultRole } = getUserRole()

  const { data } = await client.mutate({
    mutation: queries.EXPORT_ITEMS,
    context: { role: defaultRole },
    variables,
  })

  return data!
}

export const archiveExportJobs = async (ids: Set<string>) => {
  const client = getClient()
  const { defaultRole } = getUserRole()

  const { data } = await client.mutate({
    mutation: queries.ARCHIVE_EXPORT_JOBS,
    context: { role: defaultRole },
    variables: { ids: Array.from(ids) },
  })

  return data!
}

export const exportItemsKeys = {
  all: ['export'] as const,
  list: ['export', 'list'] as const,
  details: (exportJobId: string) => ['export', 'detail', exportJobId] as const,
  continueExport: (exportJobId: string) => ['export', 'continueExport', exportJobId] as const,
  exportItems: ['export', 'exportItems'] as const,
  archiveExportJobs: ['export', 'archiveExportJobs'] as const,
}
