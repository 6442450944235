import { gql, TypedDocumentNode } from '@apollo/client'

import type { RawItem } from './author-types'

export const GET_MODELS = gql`
  query get_models {
    models: ai_model(where: { is_archived: { _eq: false } }) {
      id
      flavors
      comments
      public_mapper
      name
      passages
      inputSettings: input_settings
      resourceSettings: resource_settings
      externalMetadataSettings: external_metadata_settings
      qualityMetrics: quality_metrics
      type
      internalName: internal_model_name
      isInternal: is_internal
    }
  }
`

const ITEM_CONTENT_VERSION = /* GraphQL */ `
  id
  type
  comments
  content
  qualityMetrics: quality_metrics
  externalMetadata: external_metadata
  itemBias: item_bias
  inputs
  submodels
  itemKeyCheck: item_key_check
`

export const GET_ITEM: TypedDocumentNode<{ item: RawItem }, { itemId: string; userId: string }> =
  gql`
  query item($itemId: uuid!, $userId: String!) {
    item: item_by_pk(id: $itemId) {
      id
      isArchived: is_archived
      contentType: content_type
      status
      updatedAt: updated_at
      identifier
      aiResponses: ai_responses(
        limit: 1,
        where: {status: {_eq: FAILED}}, order_by: {updated_at: desc}
      ) {
        error
      }
      aiModel: ai_model {
        id
        public_mapper
        flavors
        comments
        name
        qualityMetrics: quality_metrics
        type

        itemRationaleReferences: resource_settings(path: "$.item_rationale")
        itemRationaleInputs: resource_settings(path: "$.resource_inputs")

        externalMetadataSettings: external_metadata_settings
        internalName: internal_model_name
        optionPrefix: public_mapper(path: "$.option_prefix")
        itemContentSettings: item_content_settings
        permissions(where: { user_id: { _eq: $userId } }) {
          trustVote: trust_vote
          possibleKeys: possible_keys
          rationales
          pretest_bias
          pretest_key
          pretest_style
          pretest_content
        }
      }
      job {
        id
        itemsRequired: items_required
        ownerId: owner_id
      }
      project {
        id
        name
        ownerId: owner_id
        access(where: { user_id: { _eq: $userId } }) {
          download
          read
          write
        }
      }
      contentFilter: content_filter

      currentContentVersion: content_version(args:{content_type: "current"}) {
        ${ITEM_CONTENT_VERSION}
      }

      savedContentVersion: content_version(args:{content_type: "saved"}) {
        ${ITEM_CONTENT_VERSION}
      }

      originalContentVersion: content_version(args:{content_type: "original"}) {
        ${ITEM_CONTENT_VERSION}
      }

      votes(where: { user_id: { _eq: $userId } }) {
        vote
      }
      trustedVote: trusted_vote {
        vote
      }
    }
  }
`

export const GET_ITEM_CONTENT_VERSION: TypedDocumentNode<
  { item: Pick<RawItem, 'id' | 'status' | 'updatedAt' | 'currentContentVersion' | 'aiResponses'> },
  { itemId: string }
> = gql`
  query itemContentVersion($itemId: uuid!) {
    item: item_by_pk(id: $itemId) {
      id
      updatedAt: updated_at
      status
      aiResponses: ai_responses(
        limit: 1,
        where: {status: {_eq: FAILED}}, order_by: {updated_at: desc}
      ) {
        error
      }
      currentContentVersion: content_version(args:{content_type: "current"}) {
        ${ITEM_CONTENT_VERSION}
      }
    }
  }
`

export const GENERATE_ITEM_SET = gql`
  mutation generate_item_set(
    $customPassage: String
    $flavors: [String]
    $modelId: uuid
    $temperaturePercentage: Float
    $inputs: jsonb = {}
    $itemsRequired: Int
  ) {
    generate_item_set(
      custom_passage: $customPassage
      submodels: $flavors
      ai_model_id: $modelId
      temperature_percentage: $temperaturePercentage
      inputs: $inputs
      items_required: $itemsRequired
    ) {
      items_ids
      job_id
    }
  }
`

export type GenerateItemParams = {
  modelId?: string
  itemsRequired?: number
  itemId?: string
  temperaturePercentage?: number
  flavors?: string[]
  customPassage?: string
  title?: string
  autogenerateBlocks?: boolean
  empty?: boolean
  meta?: Record<string, any>
  inputs?: Record<string, any>
  clearStem?: boolean
  clearOptions?: boolean
  resourceId?: string
}

export const GENERATE_ITEM: TypedDocumentNode<
  { generate_item: { items_ids: string[]; job_id: string } },
  GenerateItemParams
> = gql`
  mutation generate_item(
    $modelId: uuid
    $itemsRequired: Int
    $itemId: uuid
    $temperaturePercentage: Float
    $flavors: [String]
    $customPassage: String
    $title: String
    $resourceId: String
    $autogenerateBlocks: Boolean = false
    $empty: Boolean = false
    $meta: jsonb = {}
    $inputs: jsonb = {}
    $clearStem: Boolean = false
    $clearOptions: Boolean = false
  ) {
    generate_item(
      ai_model_id: $modelId
      items_required: $itemsRequired
      item_id: $itemId
      temperature_percentage: $temperaturePercentage
      submodels: $flavors
      custom_passage: $customPassage
      title: $title
      resource_id: $resourceId
      autogenerate_blocks: $autogenerateBlocks
      empty: $empty
      meta: $meta
      inputs: $inputs
      clear_stem: $clearStem
      clear_options: $clearOptions
    ) {
      items_ids
      job_id
    }
  }
`

export const GENERATE_POSSIBLE_KEYS: TypedDocumentNode<
  { generate_possible_keys: { ok: boolean } },
  { itemId: string }
> = gql`
  mutation generate_possible_keys($itemId: uuid!) {
    generate_possible_keys(item_id: $itemId) {
      ok
    }
  }
`

export const GENERATE_RATIONALES: TypedDocumentNode<
  { generate_rationales: { ok: boolean } },
  {
    itemId: string
    keysOnly: boolean
    option?: Record<string, any>
    generateRationaleFor?: number
    itemKeyCheck?: boolean
  }
> = gql`
  mutation generate_rationales(
    $itemId: uuid!
    $keysOnly: Boolean
    $option: jsonb
    $generateRationaleFor: Int
    $itemKeyCheck: Boolean = false
  ) {
    generate_rationales(
      item_id: $itemId
      keys_only: $keysOnly
      option: $option
      generate_rationale_for: $generateRationaleFor
      item_key_check: $itemKeyCheck
    ) {
      ok
    }
  }
`

export const ITEM_BIAS: TypedDocumentNode<{ item_bias: { ok: boolean } }, { itemId: string }> = gql`
  mutation item_bias($itemId: uuid!) {
    item_bias(item_id: $itemId) {
      ok
    }
  }
`

const aiFields = `
  ai_settings
  responses
  error
  error_message
  parse_templates
  prompt
  status
  created_at
  updated_at
  id
  item_id
  version_id
`

export const DEBUG_ITEM: TypedDocumentNode<
  {
    item: {
      id: string
      ai_response: any[]
      children: {
        id: string
        ai_response: any[]
      }
      aiModel: {
        id: string
        name: string
        internalName: string
        type: string
      }
    }
  },
  { itemId: string }
> = gql`
  query debugItem($itemId: uuid!) {
    item: item_by_pk(id: $itemId) {
      id
      aiModel: ai_model {
        id
        name
        internalName: internal_model_name
        type
      }
      ai_responses {
        ${aiFields}
      }

      children {
        id
        ai_responses {
          ${aiFields}
        }
      }
    }
  }
`
