import { create } from 'zustand'

import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'

export type Client = ApolloClient<NormalizedCacheObject>

type State = {
  client: Client | null
}

type Actions = {
  setClient: (client: Client) => void
  reset: () => void
}

export const useApiState = create<State & Actions>((set) => {
  return {
    client: null,

    setClient(client) {
      set({ client })
    },

    reset() {
      set({ client: null })
    },
  }
})

// the inner components will only render after the client is set
// so we don't need to worry about the client being null
export const getClient = () => {
  const { client } = useApiState.getState()

  if (!client) {
    throw new Error('Client not set')
  }

  return client
}
