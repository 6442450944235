import { gql, TypedDocumentNode } from '@apollo/client'

import type { AiModel, Customer } from '../main-types'

export const GET_CUSTOMERS: TypedDocumentNode<{ customer: Customer[] }> = gql`
  query getCustomers {
    customer(order_by: { name: asc }) {
      id
      name
      features
      createdAt: created_at
    }
  }
`
export const GET_MODELS: TypedDocumentNode<{ models: AiModel[] }> = gql`
  subscription getModels {
    models: ai_model(where: { is_archived: { _eq: false } }, order_by: { name: asc }) {
      id
      name
      isInternal: is_internal
      type
      internalName: internal_model_name
      createdAt: created_at
    }
  }
`
export const LIST_MODELS: TypedDocumentNode<{ models: AiModel[] }> = gql`
  query getModels {
    models: ai_model(where: { is_archived: { _eq: false } }, order_by: { name: asc }) {
      id
      name
      internalName: internal_model_name
    }
  }
`

export const SWITCH_CUSTOMER: TypedDocumentNode<
  { switch_customer: { status: string } },
  { customerId: string }
> = gql`
  mutation switchCustomer($customerId: uuid!) {
    switch_customer(customer_id: $customerId) {
      status
    }
  }
`

export const ADD_CUSTOMER: TypedDocumentNode<
  { insert_customer_one: { id: string } },
  { name: string; identifier: string }
> = gql`
  mutation addCustomer($name: String!, $identifier: String!) {
    insert_customer_one(object: { name: $name, identifier: $identifier }) {
      id
    }
  }
`

type Params = { id: string; features: Customer['features']; hasOptionRationale: boolean }

export const UPDATE_CUSTOMER_FEATURES: TypedDocumentNode<
  { customer: Params & { updatedAt: string } },
  Params
> = gql`
  mutation updateCustomerFeatures($id: uuid!, $features: jsonb!, $hasOptionRationale: Boolean!) {
    customer: update_customer_by_pk(
      pk_columns: { id: $id }
      _set: { has_option_rationale: $hasOptionRationale, features: $features }
    ) {
      features
      hasOptionRationale: has_option_rationale
      updatedAt: updated_at
    }
  }
`

type ModelParams = { id: string; isInternal: boolean }

export const UPDATE_AI_MODEL: TypedDocumentNode<{ model: ModelParams }, ModelParams> = gql`
  mutation updateAiModel($id: uuid!, $isInternal: Boolean!) {
    model: update_ai_model_by_pk(pk_columns: { id: $id }, _set: { is_internal: $isInternal }) {
      id
      is_internal
    }
  }
`

export type ProBuilderIntegrationData = {
  subject_ref?: string
  centre_ref?: string
  target?: 'BILPAS' | 'PROD'
  subject_url_id: string
}

export type Integration = {
  id: string
  integration_type: string
  data: ProBuilderIntegrationData
  access_key: string
  secret_key: string
}

export const GET_CUSTOMER_INTEGRATIONS: TypedDocumentNode<{
  integration: Integration[]
}> = gql`
  query getIntegrations {
    integration {
      id
      integration_type
      data
      access_key
      secret_key
    }
  }
`

export type UpdateValues = ProBuilderIntegrationData & {
  access_key: string
  secret_key: string
}

export type UpdatCustomerIntegrationArgs = {
  integrationType: string
  data: UpdateValues
}

export const UPDATE_CUSTOMER_INTEGRATION: TypedDocumentNode<
  { update_customer_integration: { ok: boolean; error: string; result: any } },
  UpdatCustomerIntegrationArgs
> = gql`
  mutation updateCustomerIntegration(
    $integrationType: String!
    $data: jsonb
    $testConnection: Boolean
  ) {
    update_customer_integration(
      integration_type: $integrationType
      data: $data
      test_connection: $testConnection
    ) {
      ok
      error
      result
    }
  }
`

export const TEST_INTEGRATION: TypedDocumentNode<
  { update_customer_integration: { ok: boolean; error: string; result: any } },
  {
    integrationType: string
  }
> = gql`
  mutation testIntegration($integrationType: String!) {
    update_customer_integration(integration_type: $integrationType, test_connection: true) {
      ok
      error
      result
    }
  }
`
