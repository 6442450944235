import React from 'react'

import { useApolloClient } from '@apollo/client'
import { addSnack } from '@core/snack/snack-state'
import { useAppDispatch, useAppSelector } from '@core/store'
import { getModels } from '@pages/author/author-thunk'

import { actions } from '../main-slice'
import type { AiModel, Customer, FormValues } from '../main-types'
import * as queries from './admin-queries'
import type { AdminView } from './admin-views'

export type State = {
  open: boolean
  customers: Customer[] | null
  selectedCustomerId: string
  loading: boolean
  view: AdminView
  models: AiModel[] | null
}

export const initialState: State = {
  loading: false,
  open: false,
  customers: null,
  selectedCustomerId: '',
  view: 'SWITCH_CUSTOMER',
  models: null,
}

function reducer(state: State, action: Partial<State>): State {
  return { ...state, ...action }
}

const context = { role: 'internal_admin' }

export const useAdminSettings = () => {
  const client = useApolloClient()
  const user = useAppSelector((state) => state.main.user!)
  const customer = useAppSelector((state) => state.main.customer)
  const dispatch = useAppDispatch()

  const [state, setState] = React.useReducer(reducer, {
    ...initialState,
    selectedCustomerId: customer?.id || '',
  })

  const loadCustomers = React.useCallback(() => {
    client
      .query({
        query: queries.GET_CUSTOMERS,
        context,
      })
      .then(({ data }) => {
        setState({ customers: data.customer })
      })
      .catch((error) => {
        addSnack({ message: error.message, severity: 'error' })
      })
  }, [])

  const handleSwitchCustomer = async (e: React.FormEvent) => {
    e.preventDefault()
    setState({ loading: true })

    try {
      await client.mutate({
        mutation: queries.SWITCH_CUSTOMER,
        variables: { customerId: state.selectedCustomerId },
        context,
      })

      setState({ loading: false, open: false })

      addSnack({
        message:
          'Switching customer...' +
          ' The page will automatically reload in a few seconds to reflect the changes.',
        severity: 'info',
      })
    } catch (error) {
      addSnack({ message: error.message, severity: 'error' })
      setState({ loading: false })
    }
  }

  const handleUpdateCustomer = async (values: FormValues) => {
    setState({ loading: true })

    try {
      const { data } = await client.mutate({
        mutation: queries.UPDATE_CUSTOMER_FEATURES,
        variables: { id: customer!.id, ...values },
        context,
      })
      addSnack({ message: 'Customer updated', severity: 'success' })
      setState({ loading: false })
      dispatch(actions.setCustomer(data!.customer))
    } catch (error) {
      addSnack({ message: error.message, severity: 'error' })
      setState({ loading: false })
    }
  }

  const handleAddCustomer = async (values: { name: string; identifier: string }) => {
    setState({ loading: true })

    try {
      await client.mutate({
        mutation: queries.ADD_CUSTOMER,
        variables: { ...values },
        context,
      })

      addSnack({ message: 'New customer added', severity: 'success' })
      setState({ loading: false })

      return {
        success: true,
      }
    } catch (error) {
      if (error.message.includes('customer_identifier_key')) {
        const message = 'Customer with this identifier already exists'

        return {
          success: false,
          reason: message,
        }
      }

      addSnack({
        message: error.message || 'Something went wrong',
        severity: 'error',
      })
      setState({ loading: false })

      return {
        success: false,
        reason: error.message,
      }
    }
  }

  const handleUpdateModel = async (model: AiModel) => {
    setState({ loading: true })
    try {
      await client.mutate({
        mutation: queries.UPDATE_AI_MODEL,
        variables: { id: model.id, isInternal: !model.isInternal },
        context,
      })
      setState({ loading: false })
    } catch (error) {
      addSnack({ message: error.message, severity: 'error' })
      setState({ loading: false })
    }
  }

  const handleClose = () => {
    dispatch(getModels(true))
    setState({ open: false })
  }

  return {
    state,
    setState,
    handleAddCustomer,
    handleSwitchCustomer,
    handleUpdateCustomer,
    handleUpdateModel,
    user,
    customer,
    loadCustomers,
    handleClose,
  }
}

export type AdminSettings = ReturnType<typeof useAdminSettings>
