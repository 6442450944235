/* globals */
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import env from '@core/env'
import * as Sentry from '@sentry/react'

if (env.MODE !== 'development' && env.SENTRY_DSN) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.ENV,
    release: `ftg-saas-ui@${env.SENTRY_RELEASE}`,
    tracePropagationTargets: [/.*-hasura.generate.finetunelearning.com\/.*/],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.inboundFiltersIntegration({
        ignoreErrors: [
          /Fix any of the following/i,
          /Fix all of the following/i,
          /Plugin with name/i,
          /The node before which the new node is to be inserted is not a child of this node/i,
          /duplicate key value violates unique constraint/i,
          /Login required/i,
          /USER_NOT_FOUND_DB/i,
        ],
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error', 'warn'],
      }),
    ],
    tracesSampleRate: env.SENTRY_TRACES_SAMPLE_RATE,
  })
}

export default Sentry
